import { Navigate, Params, useParams } from "react-router-dom";
import {
  NotFoundState,
  PaymentPaidState,
  UnexpectedErrorState,
} from "app/components";
import { FormattedNumber, useIntl } from "react-intl";
import { FC, useState } from "react";
import { Rotate } from "@mollie/ui-assets/icons/utilitarian";
import { messages } from "app/i18n/messages";
import { PaymentLinkPaymentData } from "app/types";
import { useEffectOnce } from "react-use";
import { paymentLinksApiUrl } from "app/constants";
import { StateWithFooter } from "@mollie/revenue-collection-components";

enum Error {
  NotFound = "Payment link not found",
  BadResponse = "Bad response from server",
}

export const PaymentLinkStatusPage: FC = () => {
  const intl = useIntl();
  const [paymentLinkPaymentData, setPaymentLinkPaymentData] =
    useState<PaymentLinkPaymentData | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const params: Params<string> = useParams();

  useEffectOnce(() => {
    const apiUrl = new URL(
      `api/bff/v1/payment-links/payment/${params.paymentLinkPaymentId}/verify`,
      paymentLinksApiUrl,
    );

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            console.log("Payment link not found");
            return setError(Error.NotFound);
          } else {
            return setError(Error.BadResponse);
          }
        }

        return response.json().then((data: PaymentLinkPaymentData) => {
          setPaymentLinkPaymentData(data);
        });
      })
      .catch(() => {
        return setError(Error.BadResponse);
      });
  });

  if (error === Error.NotFound) {
    return <NotFoundState />;
  }
  if (error === Error.BadResponse) {
    return <UnexpectedErrorState />;
  }

  if (!paymentLinkPaymentData) {
    return (
      <StateWithFooter
        title={intl.formatMessage(messages.paymentLinkLandingLoadingTitle)}
        variant="loading"
        description={intl.formatMessage(
          messages.paymentLinkPaymentLoadingMessage,
        )}
      />
    );
  }

  if (["open", "prepared", "in progress"].includes(paymentLinkPaymentData.payment.status)) {
    return (
      <StateWithFooter
        title={intl.formatMessage(messages.paymentLinkPaymentPendingTitle, {
          amount: (
            <FormattedNumber
              value={Number.parseFloat(
                paymentLinkPaymentData.payment.amount.value,
              )}
              style="currency"
              currency={paymentLinkPaymentData.payment.amount.currency}
            />
          ),
          profileName: paymentLinkPaymentData.paymentLink.profileName,
        })}
        variant="loading"
        icon={Rotate}
        description={intl.formatMessage(
          messages.paymentLinkPaymentPendingDescription,
        )}
      />
    );
  }

  if (paymentLinkPaymentData.payment.status === "paid") {
    if (paymentLinkPaymentData.paymentLink.redirectUrl) {
      // deepcode ignore OR: this URL is supplied by the backend
      window.location.replace(paymentLinkPaymentData.paymentLink.redirectUrl);
    }

    return <PaymentPaidState paymentLinkPaymentData={paymentLinkPaymentData} />;
  }

  return (
    <Navigate
      to={`/${params.lang}/payment/${paymentLinkPaymentData.paymentLink.id.replace(/pl_/g, '')}`}
      replace={true}
    />
  );
};
